<template>
    <ODataLookup :data-object="dsLookupDataObject" :whereClause="getWhereClause" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <template #toolbarActions>
            <div class="form-check">
                <input class="form-check-input" id="instLookup_RestrictToContext" type="checkbox" v-model="restrictedToContext" @click="toggleContext">
                <label class="form-check-label" for="instLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
        </template>
        
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="250"></OColumn> 
    </ODataLookup>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { getDataObjectById } from 'o365-dataobject';
    import { ODataLookup } from 'o365-datalookup';
    import { context } from 'o365-modules';

    const restrictedToContext = ref(true);
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = $getDataObjectById("dsAssets_InstallationStatusesLookup");
    // const dsLookupDataObject = $getDataObjectById({
        // id: 'dsLookupDataObject' + crypto.randomUUID(),
        // viewName: 'aviw_Assets_InstallationStatusesLookup',
        // maxRecords: 25,
        // whereClause: "",
        // loadRecents: false,
        // distinctRows: true,
        // fields:
             // [{name: "ID", type: "string" },
              // {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
    // });

    const getWhereClause = computed( () => {
        var vFilter = [];

        if (restrictedToContext.value) {    
            if (props.IdPath) {
                vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
            }
            else {
                vFilter.push("'" + context.idPath + "' LIKE IdPath + '%'");
            }
        }  

        return vFilter.join(" AND ");
    });
        

    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>
